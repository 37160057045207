import { setContext } from '@sentry/nextjs';
import ApiInterface from '../app/modules/ApiInterface';
import Helper from '../app/modules/Helper';

import store from '../store';
import {
	AGREE_COOKIE_NOTICE,
	CAR_SELECT_FROM_LOCATION,
	CAR_SELECT_TO_LOCATION,
	ENABLE_VERT,
	OPEN_CHILD_INPUT,
	OPEN_INPUT,
	PASSTHRU_SET_FOUND_PARTNER,
	PASSTHRU_SET_FOUND_PARTNER_LINK,
	PASSTHRU_TOGGLE_REDIRECT_IMMEDIATELY,
	PRICE_ALERT_SET_IS_CLOSED_SIGN_UP_MODAL,
	PRICE_ALERT_SHOW_MODAL_SIGN_UP,
	REMOVE_ERRORS,
	SET_COUNTRY_CODE,
	SET_DURATION_DETECT_REDIRECT_LINK,
	SET_IS_BROWSER_SUPPORT_NOTIFICATION,
	SET_LANGUAGE,
	SET_PHONE_NUMBER,
	SET_PRICE_ALERT,
	SET_PRICE_ALERT_EMAIL,
	SET_PUSH_SUBSCRIPTION,
	SET_TICKET_DATA,
	SET_TOOLTIP_ERROR,
	SET_UID_IMID,
	SET_VAPID_KEY,
	SIGNIN_CHECKBOX_CHECKED,
	TOGGLE_AUTO_JUMP,
	TOGGLE_PREVENT_ENTER,
	TOGGLE_PREVENT_FOCUS,
	TOGGLE_PRICE_ALERT_HANDLER,
	TOGGLE_PROGRESS_LOADING,
	TOGGLE_SHOW_ERRORS,
	TOGGLE_SHOW_PHONE_NUMBER,
	USER_SIGNIN_SUCCESS,
} from './types';

import { format, setDefaultOptions } from 'date-fns';

import {
	getCarLocation,
	selectFromDate as selectFromDateCars,
	selectFromTime,
	selectToDate as selectToDateCars,
	selectToTime,
	toggleEnableReturnLocation,
} from './carActions';

import { parseFormData, submitHiddenForm } from './baseActions.utils';

import Cookies from 'js-cookie';
import { catchErrorSync } from '../app/components/Common/AppErrorBoundary';

export const openInput = (inputId) => {
	return catchErrorSync((dispatch) => {
		const w = parseInt(window.innerWidth);
		if (inputId && w < 575 && ['airport-from', 'airport-to'].includes(inputId)) {
			document.body.classList.add('opened-mobile-back-overlay');
		} else {
			if (inputId && document.getElementById(inputId) && w < 700) {
				Helper.doScrolling('#' + inputId + '-parent', 500);
			}
			document.body.classList.remove('opened-mobile-back-overlay');
		}

		if (!inputId) {
			store.dispatch(toggleAutoJump(false));
		}

		dispatch({
			type: OPEN_INPUT,
			payload: inputId,
		});
	});
};

export const openChildInput = (inputId) =>
	catchErrorSync((dispatch) => {
		dispatch({
			type: OPEN_CHILD_INPUT,
			payload: inputId,
		});
	});

export const setTooltipError = (errors) =>
	catchErrorSync((dispatch) => {
		dispatch({
			type: SET_TOOLTIP_ERROR,
			payload: errors,
		});
	});

export const removeErrors = () =>
	catchErrorSync((dispatch) => {
		dispatch({
			type: REMOVE_ERRORS,
		});
	});

export const toggleShowErrors = (toggle) =>
	catchErrorSync((dispatch) => {
		dispatch({
			type: TOGGLE_SHOW_ERRORS,
			payload: toggle,
		});
	});

export const hideAndShowErros = () =>
	catchErrorSync((dispatch) => {
		const w = parseInt(window.innerWidth);
		if (w < 1200) {
			Helper.doScrolling('#filter-form', 500);
		}
	});

export const setLocationByIATACode = (IATA, type) =>
	catchErrorSync((dispatch) => {
		ApiInterface.instance
			.fetchAirport(IATA, {
				lng: store.getState().base.lng,
			})
			.then((res) => {
				const airport = res.data;
				if (!Helper.isEmpty(airport)) {
					dispatch({
						type,
						payload: airport,
					});
				}
			})
			.catch((e) => {});
	});

export const setCityByCode = (code, type) =>
	catchErrorSync((dispatch) => {
		if (!code) return;
		ApiInterface.instance
			.fetchCity(code, {
				lng: store.getState().base.lng,
			})
			.then((res) => {
				if (!Helper.isNumeric(code)) {
					if (res.data.results.length) {
						dispatch({
							type,
							payload: Helper.convertElasticCityResultItemToAirportItem(res.data.results[0]),
						});
					}
				} else {
					const airport = res.data;
					if (!Helper.isEmpty(airport)) {
						dispatch({
							type,
							payload: airport,
						});
					}
				}
			})
			.catch((e) => {});
	});

export const setCityByCodeElastic = (code, type) =>
	catchErrorSync((dispatch) => {
		const axios = require('axios');
		const data = JSON.stringify({
			query: code,
			precision: 11,
			search_fields: {
				city_id: {},
			},
			result_fields: {
				city_id: {
					raw: {},
				},
				country: {
					raw: {},
				},
				city: {
					raw: {},
				},
				name: {
					raw: {},
				},
				state: {
					raw: {},
				},
			},
		});

		const config = {
			method: 'post',
			url:
				process.env.NEXT_PUBLIC_ELASTIC_BASE_URL +
				'/engines/' +
				process.env.NEXT_PUBLIC_ELASTIC_ZIPCITY_ENGINE_NAME +
				'/search',
			headers: {
				Authorization: 'Bearer ' + process.env.NEXT_PUBLIC_ELASTIC_AUTHORIZATION_TOKEN,
				'Content-Type': 'application/json',
			},
			data,
		};

		axios(config)
			.then((response) => {
				if (response.data.results.length) {
					dispatch({
						type,
						payload: Helper.convertElasticCityResultItemToAirportItem(response.data.results[0]),
					});
				}
			})
			.catch((error) => {
				console.log(error);
			});
	});

export const enableVert = (vert) =>
	catchErrorSync((dispatch) => {
		dispatch({
			type: ENABLE_VERT,
			payload: vert,
		});
	});

export const toggleProgressLoading = (toggle) =>
	catchErrorSync((dispatch) => {
		dispatch({
			type: TOGGLE_PROGRESS_LOADING,
			payload: toggle,
		});
	});

export const toggleAutoJump = (toggle) =>
	catchErrorSync((dispatch) => {
		dispatch({
			type: TOGGLE_AUTO_JUMP,
			payload: toggle,
		});
	});

export const agreeCookieNotice = () =>
	catchErrorSync((dispatch) => {
		try {
			localStorage.setItem('cookieNotice', true);
		} catch (error) {
			console.log('There was an error accessing LocalStorage', error);
		}

		dispatch({
			type: AGREE_COOKIE_NOTICE,
			payload: true,
		});
	});

export const sendDataToPassthruForm = (data, isTooLittleCompanies, openTwoTabs) =>
	catchErrorSync((dispatch) => {
		const queryStringData = store.dispatch(getQueryStringData());
		data = { ...queryStringData, ...data };
		if (isTooLittleCompanies) {
			const { fuid, buid, ...rest } = data;
			data = rest;
			if (openTwoTabs) {
				window.open('/passthru?' + Helper.serialize(data));
				data.tab = 'back';
				window.location.href = '/passthru?' + Helper.serialize(data);
				return;
			}
			window.location.href = '/passthru?' + Helper.serialize(data);
			return;
		}

		window.open('/passthru?' + Helper.serialize(data));
	});

export const init = (vert) =>
	catchErrorSync((dispatch) => {
		try {
			const lngLocalStorage = localStorage.getItem('i18nextLng');

			if (lngLocalStorage && lngLocalStorage.length === 2) {
				store.dispatch(setLanguage(lngLocalStorage));
			}
		} catch (error) {
			console.log('There was an error accessing LocalStorage', error);
		}

		store.dispatch(isBrowserSupportNotification());
		store.dispatch(enableVert(vert));
		const parsed = Helper.parseQueryString(window.location.search);
		const lng = store.getState().base.lng;
		const theme = process.env.NEXT_PUBLIC_FRONT_SITE_THEME;

		ApiInterface.instance
			.init(vert, {
				...parsed,
				...{
					lng,
					site_theme: theme,
				},
			})
			.then((res) => {
				const { uid, imid, priceAlert, vapidKey, closestAirport, userCountry } = res.data;
				const growthbookCallback = store.getState().growthbook.growthbookCallback;

				setContext('user', {
					id: uid,
					imid,
				});

				if (growthbookCallback) {
					growthbookCallback({
						uid,
						imid,
					});
				}

				store.dispatch(
					setUIDAndIMID({
						uid,
						imid,
					})
				);

				store.dispatch(setPriceAlert(priceAlert));
				store.dispatch(setVapidKey(vapidKey));
				store.dispatch(setCountryCode(userCountry));

				if (res.data.hasOwnProperty('closestAirport') && !Helper.isEmpty(closestAirport)) {
					store.dispatch(setCountryCode(closestAirport.countryCode));
					store.dispatch(checkToShowPhoneButton());
				}

				window.dataLayer = window.dataLayer || [];
				dataLayer.push({
					event: 'facebookPixelInit',
					pixelExternalId: `${uid}${imid}`,
				});
			})
			.catch((e) => {
				console.error('init error', e);
			});
	});

export const passthruSetFoundLink = (partner) =>
	catchErrorSync((dispatch) => {
		dispatch({
			type: PASSTHRU_SET_FOUND_PARTNER_LINK,
			payload: partner,
		});
	});

export const passthruSetFoundPartner = (partner) =>
	catchErrorSync((dispatch) => {
		dispatch({
			type: PASSTHRU_SET_FOUND_PARTNER,
			payload: partner,
		});
	});

export const submitForm = (form, data) => {
	return catchErrorSync(() => {
		store.dispatch(saveSearchParametersInCookie(data));
		const redirectByClient =
			Helper.bool(process.env.NEXT_PUBLIC_REDIRECT_BY_CLIENT) || Helper.hasPT(2) || Helper.hasPT(3);
		const queryStringData = store.dispatch(getQueryStringData());
		const parsed = Helper.parseQueryString(window.location.search, true);

		data = { ...data, ...queryStringData };

		if (redirectByClient) {
			if (data.tab === 'back' && !Helper.hasPT(3) && queryStringData.f !== '0' && parsed.autoredirect !== '1') {
				console.log('redirect immediately action');
				store.dispatch(passthruToggleRedirectImmediately(true));
			}

			const currentDate = new Date();
			const startTime = performance.now();

			ApiInterface.instance
				.clientRedirect(data.vert, parseFormData(data), {
					raxConfig: {
						retry: 1,
					},
				})
				.then((res) => {
					const endTime = performance.now();
					const responseTime = endTime - startTime;

					if (responseTime > 3000) {
						submitHiddenForm(data, form);
					}

					store.dispatch(setDurationDetectRedirectLink(new Date().getTime() - currentDate.getTime()));
					store.dispatch(passthruSetFoundLink(res.data['redirect-url']));
					store.dispatch(passthruSetFoundPartner(res.data.partner));
				})
				.catch((err) => {
					submitHiddenForm(data, form);
				});
		} else {
			submitHiddenForm(data, form);
		}
	});
};

export const getQueryStringData = () =>
	catchErrorSync((dispatch) => {
		const parsed = Helper.parseQueryString(window.location.search, true);
		const formData = {};
		if (parsed.country && parsed.country !== '') {
			formData['country'] = parsed['country'];
		}
		if (parsed['f'] && parsed['f'] !== '') {
			formData['f'] = parsed['f'];
		}
		if (parsed['fuid'] && parsed['fuid'] !== '') {
			formData['fuid'] = parsed['fuid'];
		}
		if (parsed['f-url'] && parsed['f-url'] !== '') {
			formData['f-url'] = parsed['f-url'];
		}
		if (parsed['b'] && parsed['b'] !== '') {
			formData['b'] = parsed['b'];
		}
		if (parsed['buid'] && parsed['buid'] !== '') {
			formData['buid'] = parsed['buid'];
		}
		if (parsed['b-url'] && parsed['b-url'] !== '') {
			formData['b-url'] = parsed['b-url'];
		}
		if (parsed['pt'] && parsed['pt'] !== '') {
			formData['pt'] = parsed['pt'];
		}
		if (parsed['lng'] && parsed['lng'] !== '') {
			formData['lng'] = parsed['lng'];
		}
		if (parsed['al'] && parsed['al'] !== '') {
			formData['al'] = parsed['al'];
		}
		if (parsed['bvert'] && parsed['bvert'] !== '') {
			formData['bvert'] = parsed['bvert'];
		}
		if (parsed['hchain'] && parsed['hchain'] !== '') {
			formData['hchain'] = parsed['hchain'];
		}
		if (parsed['plcid'] && parsed['plcid'] !== '') {
			formData['plcid'] = parsed['plcid'];
		}
		if (parsed['bplcid'] && parsed['bplcid'] !== '') {
			formData['bplcid'] = parsed['bplcid'];
		}
		const enabledVert = store.getState().base.enabledVert;
		switch (enabledVert) {
			case 'cars':
				if (parsed['car-class'] && (parsed['car-class'] === 'van' || parsed['car-class'] === 'pickup')) {
					formData['car-class'] = parsed['car-class'];
				}
				if (parsed['caragency'] && parsed['caragency'] !== '') formData['caragency'] = parsed['caragency'];

				break;

			default:
				break;
		}

		return formData;
	});

export const passthruToggleRedirectImmediately = (toggle) =>
	catchErrorSync((dispatch) => {
		dispatch({
			type: PASSTHRU_TOGGLE_REDIRECT_IMMEDIATELY,
			payload: toggle,
		});
	});

export const setCountryCode = (code) =>
	catchErrorSync((dispatch) => {
		dispatch({
			type: SET_COUNTRY_CODE,
			payload: code,
		});
	});

export const saveSearchParametersInCookie = (data) =>
	catchErrorSync((dispatch) => {
		Cookies.set(`${data.vert}_search_data`, JSON.stringify(data), { expires: 7, path: '/' });
	});

export const checkCookieSearchData = () =>
	catchErrorSync((dispatch) => {
		const vert = store.getState().base.enabledVert;
		let searchData = Cookies.get(`${vert}_search_data`);
		if (!searchData) return;
		searchData = JSON.parse(searchData);
		const queryStringData = Helper.parseQueryString(window.location.search, true);
		switch (vert) {
			case 'cars':
				const pickupDestinationKey = searchData['pickup-destination-key'];
				const pickupDestinationId = searchData['pickup-destination-id'];
				if (!queryStringData.hasOwnProperty('destcode')) {
					switch (pickupDestinationKey) {
						case 'city':
							store.dispatch(setCityByCode(pickupDestinationId, CAR_SELECT_FROM_LOCATION));
							break;
						case 'airport':
							store.dispatch(setLocationByIATACode(pickupDestinationId, CAR_SELECT_FROM_LOCATION));
							break;
						default:
							break;
					}
				}

				const pickupDate = new Date(searchData['pickup-time']);
				const pickupTime = searchData['pickup-t'];
				const dropOffDate = new Date(searchData['drop-off-time']);
				const dropOffTime = searchData['drop-off-t'];
				const isAfterPickupDate = Helper.isDateAfter(pickupDate);
				if (!isAfterPickupDate) {
					if (!queryStringData.hasOwnProperty('date-from')) store.dispatch(selectFromDateCars(pickupDate));
					if (!queryStringData.hasOwnProperty('date-to')) store.dispatch(selectToDateCars(dropOffDate));
				}
				if (Helper.isValidTime(pickupTime) && !queryStringData.hasOwnProperty('time-from')) {
					store.dispatch(selectFromTime(pickupTime));
				}
				if (Helper.isValidTime(dropOffTime) && !queryStringData.hasOwnProperty('time-to')) {
					store.dispatch(selectToTime(dropOffTime));
				}
				if (searchData.hasOwnProperty('drop-off-destination') && searchData['drop-off-destination']) {
					store.dispatch(toggleEnableReturnLocation(true));
					const dropOffDestinationKey = searchData['drop-off-destination-key'];
					const dropOffDestinationId = searchData['drop-off-destination-id'];
					switch (dropOffDestinationKey) {
						case 'city':
							store.dispatch(setCityByCode(dropOffDestinationId, CAR_SELECT_TO_LOCATION));
							break;
						case 'airport':
							store.dispatch(setLocationByIATACode(dropOffDestinationId, CAR_SELECT_TO_LOCATION));
							break;
						default:
							break;
					}
				}
				break;
			default:
				break;
		}
	});

export const emitGMTEvent = () =>
	catchErrorSync((dispatch) => {
		if (process.env.NEXT_PUBLIC_SHOW_PRODUCTION_SCRIPTS !== 'true') return;

		window.dataLayer = window.dataLayer || [];
		window.dataLayer.push({
			event: 'formCarSearchSubmission',
			eventCategory: 'formSubmission',
			eventAction: 'formCarSearch',
		});
		const dataCars = store.getState().car;

		const n = dataCars.selectedFromLocation.city
			? dataCars.selectedFromLocation.city
			: dataCars.selectedFromLocation.name;

		window.dataLayer.push({
			event: 'add_to_cart',
			items: [
				{
					origin: n,
					destination: dataCars.enableReturnLocation ? getCarLocation(dataCars.selectedToLocation) : '',
					start_date: format(new Date(dataCars.selectedFromDate), 'yyyy-MM-dd'),
					end_date: format(new Date(dataCars.selectedToDate), 'yyyy-MM-dd'),
					google_business_vertical: 'travel',
				},
			],
		});

		dataLayer.push({
			event: 'Search',
			items: [
				{
					content_type: 'destination',
					content_id:
						dataCars.selectedFromLocation.type === 'city'
							? dataCars.selectedFromLocation.cityID
							: dataCars.selectedFromLocation.iata,
					city: n,
					region: dataCars.selectedFromLocation.state,
					country: dataCars.selectedFromLocation.country,
					travel_start: format(new Date(dataCars.selectedFromDate), 'yyyy-MM-dd'),
					travel_end: format(new Date(dataCars.selectedToDate), 'yyyy-MM-dd'),
				},
			],
		});
	});

export const setLanguage = (lng) => {
	return catchErrorSync((dispatch) => {
		setDefaultOptions({ locale: Helper.getLocaleByCode(lng) });
		dispatch({
			type: SET_LANGUAGE,
			payload: lng,
		});
	});
};

export const setVapidKey = (vapidKey) =>
	catchErrorSync((dispatch) => {
		dispatch({
			type: SET_VAPID_KEY,
			payload: vapidKey,
		});
	});

export const setPriceAlert = (priceAlert) =>
	catchErrorSync((dispatch) => {
		dispatch({
			type: SET_PRICE_ALERT,
			payload: priceAlert,
		});
	});

export const isBrowserSupportNotification = () =>
	catchErrorSync((dispatch) => {
		dispatch({
			type: SET_IS_BROWSER_SUPPORT_NOTIFICATION,
			payload: 'Notification' in window,
		});
	});

export const setPushSubscription = (pushSubscription) =>
	catchErrorSync((dispatch) => {
		dispatch({
			type: SET_PUSH_SUBSCRIPTION,
			payload: pushSubscription,
		});
	});

export const setPriceAlertEmail = (email) =>
	catchErrorSync((dispatch) => {
		dispatch({
			type: SET_PRICE_ALERT_EMAIL,
			payload: email,
		});
	});

export const togglePreventEnter = (toggle) =>
	catchErrorSync((dispatch) => {
		dispatch({
			type: TOGGLE_PREVENT_ENTER,
			payload: toggle,
		});
	});

export const togglePreventFocus = (toggle) =>
	catchErrorSync((dispatch) => {
		dispatch({
			type: TOGGLE_PREVENT_FOCUS,
			payload: toggle,
		});
	});

export const toggleShowPhoneNumber = (toggle) =>
	catchErrorSync((dispatch) => {
		dispatch({
			type: TOGGLE_SHOW_PHONE_NUMBER,
			payload: toggle,
		});
	});

export const setPhoneNumber = (phone) =>
	catchErrorSync((dispatch) => {
		dispatch({
			type: SET_PHONE_NUMBER,
			payload: phone,
		});
		if (phone) store.dispatch(toggleShowPhoneNumber(true));
	});

export const checkToShowPhoneButton = () =>
	catchErrorSync((dispatch) => {
		const { countryCode, lng, enabledVert } = store.getState().base;
		const supportedCountryCodes = ['US', 'CA'];
		if (supportedCountryCodes.indexOf(countryCode) !== -1 || lng === 'es') {
			if (enabledVert === 'hotels') {
				// Hotels number
				store.dispatch(setPhoneNumber('855-329-5408'));
			} else if (enabledVert === 'flights') {
				// flights number
				if (lng === 'es') {
					store.dispatch(setPhoneNumber(Helper.trans('phoneNumbers.withoutPrefix')));
				} else {
					store.dispatch(setPhoneNumber('858-384-4820'));
				}
			}
		}
	});

export const setTicketData = (data) =>
	catchErrorSync((dispatch) => {
		dispatch({
			type: SET_TICKET_DATA,
			payload: data,
		});
	});

export const setDurationDetectRedirectLink = (milliseconds) =>
	catchErrorSync((dispatch) => {
		dispatch({
			type: SET_DURATION_DETECT_REDIRECT_LINK,
			payload: milliseconds,
		});
	});

export const togglePriceAlertHandler = (toggle) =>
	catchErrorSync((dispatch) => {
		dispatch({
			type: TOGGLE_PRICE_ALERT_HANDLER,
			payload: toggle,
		});
	});

export const togglePriceAlertSignUpModal = (toggle) =>
	catchErrorSync((dispatch) => {
		dispatch({
			type: PRICE_ALERT_SHOW_MODAL_SIGN_UP,
			payload: toggle,
		});
	});

export const setClosedPriceAlertSignUpModal = (toggle) =>
	catchErrorSync((dispatch) => {
		dispatch({
			type: PRICE_ALERT_SET_IS_CLOSED_SIGN_UP_MODAL,
			payload: toggle,
		});
	});

export const setLocationByZip = (code, type) =>
	catchErrorSync((dispatch) => {
		ApiInterface.instance
			.fetchZipCode(code, {
				lng: store.getState().base.lng,
			})
			.then((res) => {
				const airport = res.data;
				if (!Helper.isEmpty(airport)) {
					dispatch({
						type,
						payload: airport,
					});
				}
			})
			.catch((e) => {});
	});

export const jumpToSearchInput = () =>
	catchErrorSync((dispatch) => {
		Helper.doScrolling('.navbar', 500);
		dispatch(openInput('airport-from'));
		document.getElementById('airport-from-input')?.focus();
		document.getElementById('airport-from-input')?.select();
	});

export const sendDataToCompare = (data) => (dispatch) => {
	const queryStringData = store.dispatch(getQueryStringData());
	data = { ...data, ...queryStringData };
	window.location.href = `/${store.getState().base.enabledVert}/compare-select?${Helper.serialize(data)}`;
};

export const setUIDAndIMID = (data) => (dispatch) => {
	if (typeof localStorage !== 'undefined' && localStorage.setItem) {
		localStorage.setItem('tpdrops-uid', data.uid);
		localStorage.setItem('tpdrops-imid', data.imid);
	} else {
		console.log('localStorage is not available');
	}
	dispatch({
		type: SET_UID_IMID,
		payload: data,
	});
};

export const userSignIn = (data) =>
	catchErrorSync((dispatch) => {
		dispatch({
			type: USER_SIGNIN_SUCCESS,
			payload: data,
		});
	});

export const handleSigninCheckbox = (checked) =>
	catchErrorSync((dispatch) => {
		dispatch({
			type: SIGNIN_CHECKBOX_CHECKED,
			payload: checked,
		});
	});
