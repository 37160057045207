import { catchErrorSync } from '../app/components/Common/AppErrorBoundary';
import i18n from '../app/locales/i18n';
import Helper from '../app/modules/Helper';
import store from '../store';
import { getFbClientId } from '../utils/cookies';
import {
	emitGMTEvent,
	hideAndShowErros,
	sendDataToPassthruForm,
	setTicketData,
	setTooltipError,
	submitForm,
	togglePriceAlertSignUpModal,
	toggleProgressLoading,
} from './baseActions';
import { saveFrontFormDataToSessionStorage } from './baseActions.utils';
import { callKayakCompareAndSaveResultsToState } from './compareActions';
import {
	CAR_SELECT_FROM_DATE,
	CAR_SELECT_FROM_LOCATION,
	CAR_SELECT_FROM_TIME,
	CAR_SELECT_TO_DATE,
	CAR_SELECT_TO_LOCATION,
	CAR_SELECT_TO_TIME,
	CAR_TOGGLE_RETURN_LOCATION,
} from './types';

import { compareAsc, isBefore, isToday, startOfDay, subDays } from 'date-fns';

export const selectFromDate = (date) =>
	catchErrorSync((dispatch) => {
		if (!Helper.isValidDate(date) || isBefore(date, subDays(new Date(), 1))) {
			return;
		}
		if (date.getTime() > store.getState().car.selectedToDate.getTime()) {
			store.dispatch(selectToDate(date));
		}

		dispatch({
			type: CAR_SELECT_FROM_DATE,
			payload: date,
		});

		const errors = store.getState().base.tooltipErrors;
		delete errors['date-from'];
		store.dispatch(setTooltipError(errors));
	});

export const selectToDate = (date) =>
	catchErrorSync((dispatch) => {
		if (!Helper.isValidDate(date) || isBefore(date, subDays(new Date(), 1))) {
			return;
		}
		dispatch({
			type: CAR_SELECT_TO_DATE,
			payload: date,
		});

		const errors = store.getState().base.tooltipErrors;
		delete errors['date-to'];
		store.dispatch(setTooltipError(errors));
	});

export const selectFromTime = (time) =>
	catchErrorSync((dispatch) => {
		dispatch({
			type: CAR_SELECT_FROM_TIME,
			payload: time,
		});
		const errors = store.getState().base.tooltipErrors;
		delete errors['date-from'];
		store.dispatch(setTooltipError(errors));
	});

export const selectToTime = (time) =>
	catchErrorSync((dispatch) => {
		dispatch({
			type: CAR_SELECT_TO_TIME,
			payload: time,
		});

		const errors = store.getState().base.tooltipErrors;
		delete errors['date-to'];
		store.dispatch(setTooltipError(errors));
	});

export const toggleEnableReturnLocation = (toggle) =>
	catchErrorSync((dispatch) => {
		dispatch({
			type: CAR_TOGGLE_RETURN_LOCATION,
			payload: toggle,
		});
	});

export const selectFromLocation = (location) =>
	catchErrorSync((dispatch) => {
		dispatch({
			type: CAR_SELECT_FROM_LOCATION,
			payload: location,
		});
		const errors = store.getState().base.tooltipErrors;
		delete errors['airport-from'];
		store.dispatch(setTooltipError(errors));
	});

export const selectToLocation = (location) =>
	catchErrorSync((dispatch) => {
		dispatch({
			type: CAR_SELECT_TO_LOCATION,
			payload: location,
		});
		const errors = store.getState().base.tooltipErrors;
		delete errors['airport-to'];
		store.dispatch(setTooltipError(errors));
	});

const checkCarsFilterForm = (errors) => {
	const carState = store.getState().car;
	const priceAlert = store.getState().base.priceAlert;
	const email = store.getState().base.priceAlertEmail;

	const { selectedFromDate, selectedToDate, selectedFromTime, selectedToTime } = carState;
	const areDatesEqual = compareAsc(startOfDay(selectedFromDate), startOfDay(selectedToDate)) === 0;
	const pickupTime = +selectedFromTime.split(':')[0];
	const dropoffTime = +selectedToTime.split(':')[0];
	const now = new Date();

	const isDropOffEarlier = areDatesEqual && dropoffTime < pickupTime;
	const isDropOffSameTime = areDatesEqual && dropoffTime === pickupTime;
	const isPickupEarlierThanNow = isToday(selectedFromDate) && pickupTime <= now.getHours();
	const isDropOffHourValid = areDatesEqual && dropoffTime - pickupTime < 2;

	if (isPickupEarlierThanNow) {
		errors['date-from'] = i18n.t('validation.cars.pickUpTime');
	}

	if (isDropOffEarlier || isDropOffSameTime || isDropOffHourValid) {
		errors['date-to'] = i18n.t('validation.cars.dropOffTime');
	}

	if (
		priceAlert.indexOf('web-push') === -1 &&
		priceAlert.indexOf('email') !== -1 &&
		email &&
		!Helper.isValidEmail(email)
	) {
		errors['price-alert-email'] = i18n.t('validation.priceAlert.email');
	}

	if (!Object.keys(carState.selectedFromLocation).length) {
		errors['airport-from'] = i18n.t('validation.cars.pickUpLocation');
	}
	if (carState.enableReturnLocation) {
		if (!Object.keys(carState.selectedToLocation).length) {
			errors['airport-to'] = i18n.t('validation.cars.dropOffLocation');
		}
	}
};

export const doSearchCars = () =>
	catchErrorSync(() => {
		const baseState = store.getState().base;
		const errors = {};
		checkCarsFilterForm(errors);

		store.dispatch(setTooltipError(errors));
		if (
			Helper.isEmpty(errors) &&
			!baseState.isClosedPriceAlertSignUpModal &&
			baseState.priceAlertHandlerChecked &&
			document.body.clientWidth >= 992
		) {
			store.dispatch(togglePriceAlertSignUpModal(true));
			return;
		}
		if (Helper.isEmpty(errors)) {
			store.dispatch(emitGMTEvent());
			const parsed = Helper.parseQueryString(window.location.search, true);
			let preventOpenFrontTab = false;
			if (parsed.hasOwnProperty('f') && parsed.f === '0') {
				preventOpenFrontTab = true;
			}
			if (parsed.hasOwnProperty('autoredirect') && parsed.autoredirect === '1') {
				preventOpenFrontTab = true;
			}
			let openBackTabTimeout = 500;
			if (preventOpenFrontTab) {
				openBackTabTimeout = 100;
			}

			const frontFromData = store.dispatch(getFormData('front'));
			saveFrontFormDataToSessionStorage(frontFromData);

			setTimeout(() => {
				if (!preventOpenFrontTab) {
					store.dispatch(submitFormFront());
				}
				setTimeout(() => {
					store.dispatch(toggleProgressLoading(true));
					store.dispatch(submitFormBack());
				}, openBackTabTimeout);
			}, 10);
		} else {
			store.dispatch(hideAndShowErros());
		}
	});

export const doSearchPartners =
	(isKcForm = false) =>
	() => {
		const errors = {};
		checkCarsFilterForm(errors);
		store.dispatch(setTooltipError(errors));
		if (!Helper.isEmpty(errors)) {
			return store.dispatch(hideAndShowErros());
		}

		const baseState = store.getState().base;
		if (
			Helper.isEmpty(errors) &&
			!baseState.isClosedPriceAlertSignUpModal &&
			baseState.priceAlertHandlerChecked &&
			document.body.clientWidth >= 992
		) {
			store.dispatch(togglePriceAlertSignUpModal(true));
			return;
		}

		const frontFromData = store.dispatch(getFormData('front'));
		for (const key in frontFromData) {
			if (key.startsWith('_')) {
				const newKey = key.replace('_', '');
				const dataArray = frontFromData[key].split('|');
				// sum adults and children
				frontFromData[newKey] = dataArray.map((e) => parseInt(e, 10)).reduce((acc, a) => acc + a, 0);
				delete frontFromData[key];
			}
		}
		callKayakCompareAndSaveResultsToState(frontFromData, isKcForm);
	};

export function getCarLocation(obj) {
	let pickupDestinationObj = obj.iata;
	if (!pickupDestinationObj) {
		if (obj.loctype !== 'addr') {
			pickupDestinationObj = [];
			pickupDestinationObj.push(obj.name);
			if (obj.stateCode && obj.country === 'United States') {
				pickupDestinationObj.push(obj.stateCode);
			}
			if (obj.state && obj.country === 'United States') {
				pickupDestinationObj.push(obj.state);
			}
			if (obj.country) {
				pickupDestinationObj.push(obj.country);
			}
			pickupDestinationObj = pickupDestinationObj.join(',');
		} else {
			pickupDestinationObj = obj.smartyDisplay.replaceAll(', ', ',').replaceAll(' ', '-');
		}
	}
	return pickupDestinationObj;
}

export const submitFormBack = () =>
	catchErrorSync(() => {
		const form = document.getElementById('redirect-back-form');
		const data = store.dispatch(getFormData('back'));
		store.dispatch(setTicketData(data));
		store.dispatch(submitForm(form, data));
	});

export const submitFormFront = () =>
	catchErrorSync(() => {
		store.dispatch(sendDataToPassthruForm(store.dispatch(getFormData('front'))));
	});

export const getFormData = (formType) =>
	catchErrorSync(() => {
		const data = store.getState().car;
		const from = data.selectedFromLocation;
		const enableReturnLocation = data.enableReturnLocation;
		const to = data.selectedToLocation;
		const fromDate = data.selectedFromDate;
		const toDate = data.selectedToDate;
		const fromTime = data.selectedFromTime;
		const toTime = data.selectedToTime;

		const pickupDestination = getCarLocation(from);
		let pickupDestinationID = null;
		let pickupDestinationKey = null;
		if (from.hasOwnProperty('airportID') || from.type === 'airport') {
			pickupDestinationID = from.iata;
			pickupDestinationKey = 'airport';
		} else if (from.hasOwnProperty('cityID')) {
			pickupDestinationID = from.cityID;
			pickupDestinationKey = 'city';
		}

		// RENTAL CONTRACT DURATION
		const date_from = new Date(fromDate);
		const date_to = new Date(toDate);
		const diffTime = Math.abs(date_to - date_from);
		const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
		const dta = Math.abs(date_from - Date.now());
		const dtaDiff = Math.ceil(dta / (1000 * 60 * 60 * 24));

		const fbp = getFbClientId();

		const formData = {
			tab: formType,
			vert: store.getState().base.enabledVert,
			'pickup-destination': pickupDestination,
			'pickup-time': Helper.toISODateTimeString(fromDate, fromTime),
			'drop-off-time': Helper.toISODateTimeString(toDate, toTime),
			'pickup-t': fromTime,
			'drop-off-t': toTime,
			'pickup-destination-id': pickupDestinationID,
			'pickup-destination-key': pickupDestinationKey,
			'rental-duration': diffDays,
			dta: dtaDiff,
			'state-code': from.stateCode,
			'country-code': from.countryCode,
			...(fbp && { fbp }),
		};

		if (store.getState().base.priceAlertEmail) {
			formData['email'] = store.getState().base.priceAlertEmail;
		}
		if (enableReturnLocation) {
			const dropoffDestination = getCarLocation(to);
			formData['drop-off-destination'] = dropoffDestination;
			let dropOffDestinationID = null;
			let dropOffDestinationKey = null;
			if (to.hasOwnProperty('airportID')) {
				dropOffDestinationID = to.airportID;
				dropOffDestinationKey = 'airport';
			} else if (to.hasOwnProperty('cityID')) {
				dropOffDestinationID = to.cityID;
				dropOffDestinationKey = 'city';
			}
			formData['drop-off-destination-id'] = dropOffDestinationID;
			formData['drop-off-destination-key'] = dropOffDestinationKey;
		}
		return formData;
	});

export const prePopulateDataFromQueryString = () =>
	catchErrorSync(() => {
		const parsed = Helper.parseQueryString(window.location.search, true);
		if (parsed.hasOwnProperty('date-from') && parsed['date-from']) {
			let dateFrom = parsed['date-from'];
			if (Helper.isValidDateFormat(dateFrom)) {
				dateFrom = Helper.convertStringDateToDateObject(dateFrom);
				store.dispatch(selectFromDate(dateFrom));
			}
		}
		if (parsed.hasOwnProperty('date-to') && parsed['date-to']) {
			let dateTo = parsed['date-to'];
			if (Helper.isValidDateFormat(dateTo)) {
				dateTo = Helper.convertStringDateToDateObject(dateTo);
				store.dispatch(selectToDate(dateTo));
			}
		}
		if (parsed.hasOwnProperty('time-to') && parsed['time-to']) {
			const timeTo = parsed['time-to'];
			if (Helper.isValidTimeFormat(timeTo)) {
				store.dispatch(selectToTime(timeTo));
			}
		}
		if (parsed.hasOwnProperty('time-from') && parsed['time-from']) {
			const timeFrom = parsed['time-from'];
			if (Helper.isValidTimeFormat(timeFrom)) {
				store.dispatch(selectFromTime(timeFrom));
			}
		}
	});
