import {
	AGREE_COOKIE_NOTICE,
	BaseActionTypes,
	ENABLE_VERT,
	OPEN_CHILD_INPUT,
	OPEN_INPUT,
	PRICE_ALERT_SET_IS_CLOSED_SIGN_UP_MODAL,
	PRICE_ALERT_SHOW_MODAL_SIGN_UP,
	REMOVE_ERRORS,
	SET_COUNTRY_CODE,
	SET_IS_BROWSER_SUPPORT_NOTIFICATION,
	SET_LANGUAGE,
	SET_PHONE_NUMBER,
	SET_PRICE_ALERT,
	SET_PRICE_ALERT_EMAIL,
	SET_PUSH_SUBSCRIPTION,
	SET_TOOLTIP_ERROR,
	SET_UID_IMID,
	SET_VAPID_KEY,
	SIGNIN_CHECKBOX_CHECKED,
	TOGGLE_AUTO_JUMP,
	TOGGLE_PREVENT_ENTER,
	TOGGLE_PREVENT_FOCUS,
	TOGGLE_PRICE_ALERT_HANDLER,
	TOGGLE_PROGRESS_LOADING,
	TOGGLE_SHOW_ERRORS,
	TOGGLE_SHOW_PHONE_NUMBER,
	USER_SIGNIN_SUCCESS,
} from '../actions/types';

interface IBaseState {
	openedInput: string | null;
	openedChildInput: string | null;
	tooltipErrors: object;
	enabledVert: string;
	cookieNotice: boolean;
	enableProgressLoading: boolean;
	autoJump: boolean;
	showErrors: boolean;
	preventEnter: boolean;
	preventFocus: boolean;
	isClosedPriceAlertSignUpModal: boolean;
	priceAlertShowModalSignUp: boolean;
	priceAlertHandlerChecked: boolean;
	showPhoneNumber: boolean;
	isCheckedPriceAlertConfirmation: boolean;
	isBrowserSupportNotification: boolean;
	countryCode: string | null;
	phoneNumber: string | null;
	vapidKey: string | null;
	lng: string;
	priceAlert: string[];
	random: number;
	pushSubscription: object;
	priceAlertEmail: string;
	isUserLoggedIn?: boolean;
	signinCheckbox?: boolean;
}

const initialState: IBaseState = {
	openedInput: null,
	openedChildInput: null,
	tooltipErrors: {},
	enabledVert: 'cars',
	cookieNotice: false,
	enableProgressLoading: false,
	autoJump: true,
	showErrors: true,
	countryCode: null,
	lng: 'en',
	vapidKey: null,
	priceAlert: [],
	isCheckedPriceAlertConfirmation: false,
	isBrowserSupportNotification: false,
	random: 0,
	pushSubscription: {},
	priceAlertEmail: '',
	preventEnter: false,
	preventFocus: false,
	showPhoneNumber: false,
	phoneNumber: null,
	priceAlertHandlerChecked: false,
	priceAlertShowModalSignUp: false,
	isClosedPriceAlertSignUpModal: false,
	isUserLoggedIn: false,
	signinCheckbox: true,
};
const baseReducer = (state = initialState, action: BaseActionTypes) => {
	switch (action.type) {
		case OPEN_INPUT:
			return {
				...state,
				openedInput: action.payload,
			};
		case OPEN_CHILD_INPUT:
			return {
				...state,
				openedChildInput: action.payload,
			};
		case SET_TOOLTIP_ERROR:
			return {
				...state,
				tooltipErrors: action.payload,
			};
		case REMOVE_ERRORS:
			return {
				...state,
				tooltipErrors: {},
			};
		case TOGGLE_SHOW_ERRORS:
			return {
				...state,
				showErrors: action.payload,
			};
		case ENABLE_VERT:
			return {
				...state,
				enabledVert: action.payload,
			};
		case AGREE_COOKIE_NOTICE:
			return {
				...state,
				cookieNotice: action.payload,
			};
		case TOGGLE_PROGRESS_LOADING:
			return {
				...state,
				enableProgressLoading: action.payload,
			};
		case TOGGLE_AUTO_JUMP:
			return {
				...state,
				autoJump: action.payload,
			};
		case SET_COUNTRY_CODE:
			return {
				...state,
				countryCode: action.payload,
			};
		case SET_LANGUAGE:
			return {
				...state,
				lng: action.payload,
			};
		case SET_VAPID_KEY:
			return {
				...state,
				vapidKey: action.payload,
			};
		case SET_PRICE_ALERT:
			return {
				...state,
				priceAlert: action.payload,
				random: Math.random(),
			};
		case SET_IS_BROWSER_SUPPORT_NOTIFICATION:
			return {
				...state,
				isBrowserSupportNotification: action.payload,
			};
		case SET_PUSH_SUBSCRIPTION:
			return {
				...state,
				pushSubscription: action.payload,
			};
		case SET_PRICE_ALERT_EMAIL:
			return {
				...state,
				priceAlertEmail: action.payload,
			};
		case TOGGLE_SHOW_PHONE_NUMBER:
			return {
				...state,
				showPhoneNumber: action.payload,
			};
		case SET_PHONE_NUMBER:
			return {
				...state,
				phoneNumber: action.payload,
			};
		case TOGGLE_PREVENT_ENTER:
			return {
				...state,
				preventEnter: action.payload,
			};
		case TOGGLE_PREVENT_FOCUS:
			return {
				...state,
				preventFocus: action.payload,
			};
		case TOGGLE_PRICE_ALERT_HANDLER:
			return {
				...state,
				priceAlertHandlerChecked: action.payload,
			};
		case PRICE_ALERT_SHOW_MODAL_SIGN_UP:
			return {
				...state,
				priceAlertShowModalSignUp: action.payload,
			};
		case PRICE_ALERT_SET_IS_CLOSED_SIGN_UP_MODAL:
			return {
				...state,
				isClosedPriceAlertSignUpModal: action.payload,
			};
		case SET_UID_IMID:
			return {
				...state,
				uid: action.payload.uid,
				imid: action.payload.imid,
			};
		case USER_SIGNIN_SUCCESS:
			return {
				...state,
				isUserLoggedIn: action.payload,
			};
		case SIGNIN_CHECKBOX_CHECKED:
			return {
				...state,
				signinCheckbox: action.payload,
			};
		default:
			return state;
	}
};

export default baseReducer;
