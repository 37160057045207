import {LocationObject} from "../app/components/Cars/Blog";

export type LocationForScheme = {
  url: string,
  description: string,
  name: string,
}

interface IBlogState {
  locationForScheme: LocationObject | null
}

const initialState: IBlogState = {
  locationForScheme: null,
};

export const SET_BLOG_LOCATION = 'SET_BLOG_LOCATION';


export default function (state = initialState, action: any) {
  switch (action.type) {
    case SET_BLOG_LOCATION:
      return {
        ...state,
        locationForScheme: action.payload
      };

    default:
      return state;
  }
}
