import type { NextPage } from 'next';
import { NextSeo } from 'next-seo';
import dynamic from 'next/dynamic';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { checkCookieSearchData, init } from '../actions/baseActions';
import { setBlogLocation } from '../actions/blogActions';
// @ts-ignore
import store from '../store';

const Cars = dynamic(() => import('../app/components/Cars/Cars'), { ssr: false });

const Home: NextPage = (props) => {
	const dispatch = useDispatch();
	const { t } = useTranslation();

	useEffect(() => {
		init('cars')(dispatch);
		checkCookieSearchData()(dispatch);
	}, []);

	return (
		<>
			<NextSeo
				title={t('metadata.cars.title')}
				description={t('metadata.cars.description')}
				canonical={process.env.NEXT_PUBLIC_SITE_URL}
				openGraph={{
					url: `${process.env.NEXT_PUBLIC_SITE_URL}`,
					title: t('metadata.cars.title'),
					description: t('metadata.cars.description'),
					site_name: process.env.NEXT_PUBLIC_SITE_NAME,
				}}
			/>
			<Cars
				query={
					// @ts-ignore
					props.query
				}
			/>
		</>
	);
};

export async function getServerSideProps(ctx: any) {
	const locationForScheme = {
		url: `https://therentalradar.com/`,
	};
	// @ts-ignore
	store.dispatch(setBlogLocation(locationForScheme));
	return {
		props: {
			query: ctx.query,
		}, // will be passed to the page component as props
	};
}

export default Home;
