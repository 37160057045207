import { combineReducers } from 'redux';
import baseReducer from './baseReducer';
import blogReducer from './blogReducer';
import carReducer from './carReducer';
import compareReducer from './compareReducer';
import growthbookReducer from './growthbookReducer';
import passthruReducer from './passthruReducer';

export default combineReducers({
	base: baseReducer,
	car: carReducer,
	passthru: passthruReducer,
	blog: blogReducer,
	compare: compareReducer,
	growthbook: growthbookReducer,
});
