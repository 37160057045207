import { add } from 'date-fns';
import {
	CAR_SELECT_FROM_DATE,
	CAR_SELECT_FROM_LOCATION,
	CAR_SELECT_FROM_TIME,
	CAR_SELECT_TO_DATE,
	CAR_SELECT_TO_LOCATION,
	CAR_SELECT_TO_TIME,
	CAR_TOGGLE_RETURN_LOCATION,
	CarActionTypes,
} from '../actions/types';
import { IAirportItem } from '../app/components/Common/AirportSelect/Item';

interface ICarsState {
	selectedFromDate: Date;
	selectedToDate: Date;
	selectedFromTime: string;
	selectedToTime: string;
	selectedFromLocation?: IAirportItem | object;
	selectedToLocation?: IAirportItem | object;
	enableReturnLocation: boolean;
}

const initialState: ICarsState = {
	selectedFromDate: add(new Date(), { days: 7 }),
	selectedToDate: add(new Date(), { days: 14 }),
	selectedFromTime: '10:00',
	selectedToTime: '10:00',
	selectedFromLocation: {},
	selectedToLocation: {},
	enableReturnLocation: false,
};
const carReducer = (state = initialState, action: CarActionTypes) => {
	switch (action.type) {
		case CAR_SELECT_FROM_DATE:
			return {
				...state,
				selectedFromDate: action.payload,
			};
		case CAR_SELECT_TO_DATE:
			return {
				...state,
				selectedToDate: action.payload,
			};
		case CAR_SELECT_FROM_TIME:
			return {
				...state,
				selectedFromTime: action.payload,
			};
		case CAR_SELECT_TO_TIME:
			return {
				...state,
				selectedToTime: action.payload,
			};
		case CAR_SELECT_FROM_LOCATION:
			return {
				...state,
				selectedFromLocation: action.payload,
			};
		case CAR_SELECT_TO_LOCATION:
			return {
				...state,
				selectedToLocation: action.payload,
			};

		case CAR_TOGGLE_RETURN_LOCATION:
			return {
				...state,
				enableReturnLocation: action.payload,
			};

		default:
			return state;
	}
};
export default carReducer;
