import {
    SET_COMPARE_DATA, SET_COMPARE_RESULT, SET_FULL_SCREEN_INDEX,
} from "./compareTypes";
import ApiInterface from "../app/modules/ApiInterface";
import store from "../store";
import Helper from "../app/modules/Helper";
import Cookies from 'js-cookie'
import {encode} from "js-base64";
import {sendDataToPassthruForm} from "./baseActions";
import {getFormData} from "./carActions";
import {loadFrontFormDataFromSessionStorage} from "./baseActions.utils";


export const setCompareData = (data) => dispatch => {
    dispatch({
        type: SET_COMPARE_DATA,
        payload: data
    })
};

export const setCompareResult = (data) => dispatch => {
    dispatch({
        type: SET_COMPARE_RESULT,
        payload: data
    })
};
export const setCompareFullScreenIndex = (index) => dispatch => {
    dispatch({
        type: SET_FULL_SCREEN_INDEX,
        payload: index
    })
};

export const reverseCompare = () => dispatch => {
    let parsed = store.getState().compare.data
    switch (store.getState().base.enabledVert) {
        case "flights":
            // @ts-ignore
            const originIATA = parsed['origin-iata'];
            // @ts-ignore
            const destinationIATA = parsed['destination-iata'];
            // @ts-ignore
            parsed['origin-iata'] = destinationIATA;
            // @ts-ignore
            parsed['destination-iata'] = originIATA;
            break;
        case "cars":
            // @ts-ignore
            const dropOffDestination = parsed['drop-off-destination'];
            // @ts-ignore
            const dropOffDestinationID = parsed['drop-off-destination-id'];
            // @ts-ignore
            const dropOffDestinationKey = parsed['drop-off-destination-key'];
            // @ts-ignore
            const pickupDestination = parsed['pickup-destination'];
            // @ts-ignore
            const pickupDestinationID = parsed['pickup-destination-id'];
            // @ts-ignore
            const pickupDestinationKey = parsed['pickup-destination-key'];
            // @ts-ignore
            parsed['pickup-destination'] = dropOffDestination;
            parsed['pickup-destination-id'] = dropOffDestinationID;
            parsed['pickup-destination-key'] = dropOffDestinationKey;
            // @ts-ignore
            parsed['drop-off-destination'] = pickupDestination;
            parsed['drop-off-destination-id'] = pickupDestinationID;
            parsed['drop-off-destination-key'] = pickupDestinationKey;
            break;
    }
    store.dispatch(setCompareData(parsed))
    store.dispatch(fetchCompareResult(parsed))
};


export const callKayakCompareAndSaveResultsToState = (data, isKcForm) => {
    store.dispatch(setCompareResult(null))
    store.dispatch(setCompareData(data))
    const params = new URLSearchParams();
    for (let i = 0; i < Object.keys(data).length; i++) {
        // @ts-ignore
        const currentKey = Object.keys(data)[i];
        let value = data[currentKey];
        if (['children', 'adults'].includes(currentKey)) {
            console.log(currentKey, value);
            if (Array.isArray(value)) {
                value = value.map((item) => parseInt(item)).reduce((a, b) => a + b, 0)
            }
        }
        params.append(currentKey, value);
    }

    ApiInterface.instance.kayakCompare(store.getState().base.enabledVert, params)
        .then((res) => {
            //filter json for name not equal  Opodo

            res.data.kayakResponse.compareToItems = res.data.kayakResponse.compareToItems.filter(function (item) {
                return item.name !== 'Opodo'
            })

            store.dispatch(setCompareResult(res.data))
            if (res.data.kayakResponse.compareToItems.length === 0) {
                const customFormData = loadFrontFormDataFromSessionStorage();
                const isTooLittleCompanies = true;
                const formToSend = customFormData || store.dispatch(getFormData('front'));
                if (Helper.isKayakCompareFlowFront()) {
                    formToSend.f = 'k';
                    formToSend.tab = 'front';
                }
                else if (Helper.isKayakCompareFlowBack()) {
                    formToSend.b = 'k';
                    formToSend.tab = 'back';
                }
                console.log("CUSTOM FORM DATA", customFormData);
                console.log("FRONT FORM DATA", store.dispatch(getFormData('front')));
                console.log('FORM TO SEND', JSON.stringify(formToSend));
                const openTwoTabs = Helper.isKayakCompareFlowFront();
                store.dispatch(sendDataToPassthruForm(
                    formToSend, isTooLittleCompanies, openTwoTabs
                ));
                // store.dispatch(setCompareResult({}))
                return;
            }

            sessionStorage.setItem(`${store.getState().base.enabledVert}-compare-result`, JSON.stringify(res.data));
            for (let i = 0; i < res.data.kayakCookies.length; i++) {
                const cookieData = res.data.kayakCookies[i].split(';');
                const cookieName = cookieData[0].split('=')[0]
                const cookieValue = cookieData[0].split('=')[1]
                let expire = cookieData[2].split('=')[1]

                expire = Helper.getDiffDaysBetweenTwoDates(new Date(), new Date(expire))
                Cookies.set(cookieName, cookieValue, {expires: expire, path: '/', domain: res.data.kayakDomain})

            }

            res.data.kayakResponse.compareToItems.forEach(function (item) {
                ApiInterface.instance.kayakImpress(store.getState().base.enabledVert, {
                    q: encode(item.impressionUrl),
                    domain: res.data.kayakDomain,
                })
                    .then(r => {

                    })
            })


        }).catch((err) => {

    })
}

export const fetchCompareResult = (data) => dispatch => {
    callKayakCompareAndSaveResultsToState(data);
};

