'use client';
import React, { ReactNode, useState } from 'react';

interface Props {
	children?: ReactNode;
}

interface State {
	hasError: boolean;
}

interface CallbackFunction {
	(...args: any[]): any;
}

export const catchError = (callback: CallbackFunction) => {
	return async (...args: any) => {
		try {
			return await callback(...args);
		} catch (error) {
			console.error('Uncaught and reported error:', error);
			throw error;
		}
	};
};

export const catchErrorSync = (callback: CallbackFunction) => {
	return (...args: any) => {
		try {
			return callback(...args);
		} catch (error) {
			console.error('Uncaught and reported error:', error);
			throw error;
		}
	};
};

function AppErrorBoundary(props: Props) {
	const [state, setState] = useState<State>({ hasError: false });

	function handleCatch(error: Error, errorInfo: React.ErrorInfo) {
		console.error('Uncaught error:', error, errorInfo);
		setState({ hasError: true });
	}

	if (state.hasError) {
		// Render fallback UI
		return <h1>Something went wrong.</h1>;
	}

	return (
		<>
			{React.Children.map(props.children, (child) => {
				return React.cloneElement(child as React.ReactElement<any>, {
					onError: handleCatch,
				});
			})}
		</>
	);
}

export default AppErrorBoundary;
