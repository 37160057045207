import { de, enGB, es, fr, it, pt, ru } from 'date-fns/locale';

export const localeMap: Record<string, Locale> = { ru, 'en-GB': enGB, pt, es, de, fr, it };

export const VERTICAL = 'cars';

export const times: string[] = [
	'00',
	'01',
	'02',
	'03',
	'04',
	'05',
	'06',
	'07',
	'08',
	'09',
	'10',
	'11',
	'12',
	'13',
	'14',
	'15',
	'16',
	'17',
	'18',
	'19',
	'20',
	'21',
	'22',
	'23',
];
