import React, {PureComponent} from 'react';
import {connect} from "react-redux";
import {LocationForScheme} from "../../../reducers/blogReducer";

type SchemeHeadProps = {
  locationForScheme: LocationForScheme
}

class SchemeHead extends PureComponent<SchemeHeadProps> {
  render() {
    const { locationForScheme } = this.props;
    const processedLocation = {
      url: locationForScheme?.url || 'https://therentalradar.com/',
      name: locationForScheme?.name || 'The Rental Radar',
      description: locationForScheme?.description || 'Compare prices between the top car rental sites & find cheap car hire prices. Get Price drops alerts when rental price changes. Search for cheap auto rentals',
    }
    return (
      <React.Fragment>
        <script id={"facebook-1"} type='application/ld+json'
                dangerouslySetInnerHTML={{
                  __html: `  {
        "@context":"https://schema.org",
        "@type":"WebSite",
        "@id":"#website",
        "url":"${processedLocation.url}",
        "name":"${processedLocation.name}",
        "potentialAction":
        {
            "@type":"SearchAction",
            "target":"https://therentalradar.com/?s={search_term_string}",
            "query-input":"required name=search_term_string"
        }
    }`
                }}
        />
        <script id="facebook-2" type="application/ld+json"
                dangerouslySetInnerHTML={{
                  __html: `  {
        "@context": "https://schema.org",
        "@type": "TravelAgency",
        "name": "${processedLocation.name}",
        "description" : "${processedLocation.description}",
        "email": "info@therentalradar.com",
        "logo" : "https://therentalradar.com/images/logos/new-logo.png?v1.1",
        "telephone": "+18583844820",
        "url" :"${processedLocation.url}",
        "image" : "https://therentalradar.com/images/logos/new-logo.png?v1.1",
        "priceRange" : "₪-₪",
        "contactPoint":
        [
            {
                "@type": "ContactPoint",
                "telephone": "+18583844820",
                "contactType": "customer service",
                "email": "info@therentalradar.com",
                "areaServed":
                [
                    "Israel"
                ],
                "availableLanguage":
                [
                    "English"
                ]
            }
        ],
        "address":
        {
            "@context": "https://schema.org",
            "@type": "PostalAddress",
            "name": "Therentalradar",
            "streetAddress": "Kordova St",
            "addressRegion": "Tel Aviv",
            "postalCode": "61001",
            "addressCountry": "Israel"
        },
        "sameAs":
        [
            "https://www.facebook.com/Travelpricedropscom-107709767237425"
        ]
    }`
                }}
        />
      </React.Fragment>
    );
  }
}

// @ts-ignore
const mapStateToProps = state => ({
  locationForScheme: state.blog.locationForScheme,
});

export default connect(mapStateToProps, {})(SchemeHead);
